import conf from "../config.js";

const context = `${conf.CONFIG_APP_BUILD} ${conf.CONFIG_APP_VERSION}`;

export const realmSDK = Object.freeze({
  WEB: "web",
  NODE: "node",
  REACT_NATIVE: "react-native",
});

export const dataOrigin = Object.freeze({
  LOCAL: "local",
  WEB: "web",
});

export const mdbCollection = Object.freeze({
  CLIENT: "Client",
  CONSUMPTION: "Consumption",
  DECK: "Deck",
  ENTRY: "Entry",
  ENTRY_REVIEW: "EntryReview",
  ENTRY_REVIEW_SESSION: "EntryReviewSession",
  GLOSSARY: "Glossary",
  GRID_SETTING: "GridSetting",
  JOB: "Job",
  NOTIFICATION: "Notification",
  PRACTICE_CHANNEL: "PracticeChannel",
  PRACTICE_MATERIAL: "PracticeMaterial",
  PRACTICE_SPEAKER: "PracticeSpeaker",
  SUBSCRIPTION: "Subscription",
  UPLOAD: "Upload",
  APP_VERSION: "AppVersion",
  USER_SETTING: "UserSetting",
  USER: "User",
  GROUP: "Group",
});

export const mdbErrors = Object.freeze({
  REALM_UNDEFINED: `Realm is undefined | ${context}`,
  NOT_FOUND_CLIENT: `Client not found | ${context}`,
  NOT_FOUND_CONSUMPTION: `Consumption not found | ${context}`,
  NOT_FOUND_DECK: `Deck not found | ${context}`,
  NOT_FOUND_ENTRY: `Entry not found | ${context}`,
  NOT_FOUND_ENTRY_REVIEW: `EntryReview not found | ${context}`,
  NOT_FOUND_ENTRY_REVIEW_SESSION: `EntryReviewSession not found | ${context}`,
  NOT_FOUND_GLOSSARY: `Glossary not found | ${context}`,
  NOT_FOUND_GRID_SETTING: `GridSetting not found | ${context}`,
  NOT_FOUND_JOB: `Job not found | ${context}`,
  NOT_FOUND_NOTIFICATION: `Notification not found | ${context}`,
  NOT_FOUND_PRACTICE_CHANNEL: `PracticeChannel not found | ${context}`,
  NOT_FOUND_PRACTICE_MATERIAL: `PracticeMaterial not found | ${context}`,
  NOT_FOUND_PRACTICE_SPEAKER: `PracticeSpeaker not found | ${context}`,
  NOT_FOUND_SUBSCRIPTION: `Subscription not found | ${context}`,
  NOT_FOUND_UPLOAD: `Upload not found | ${context}`,
  NOT_FOUND_APP_VERSION: `AppVersion not found | ${context}`,
  NOT_FOUND_USER_SETTING: `UserSetting not found | ${context}`,
  NOT_FOUND_USER: `User not found | ${context}`,
  NOT_FOUND_GROUP: `Group not found | ${context}`,
  NOT_SAVEABLE_COLUMN: `Column not saveable | ${context}`,
  /* Not mongoDB errors, but let's leave it here */
  UNDEFINED_USER: `Undefined user | ${context}`,
  UNDEFINED_USER_ID: `Undefined userID | ${context}`,
  UNDEFINED_FIELD: `Undefined field | ${context}`,
  UNDEFINED_VALUE: `Undefined value | ${context}`,
  UNDEFINED_CREATOR: `Undefined creator | ${context}`,
  UNDEFINED_MEMBER: `Undefined member | ${context}`,
  UNDEFINED_GLOSSARY: `Undefined glossary | ${context}`,
  UNDEFINED_GROUP: `Undefined group | ${context}`,
  UNDEFINED_COLUMN: `Undefined column | ${context}`,
  UNDEFINED_GROUP_ID: `Undefined group_id | ${context}`,
  UNDEFINED_GLOSSARY_ID: `Undefined group_id | ${context}`,
  UNDEFINED_NAME: `Undefined name | ${context}`,
  UNDEFINED_CUSTOM_ABILITIES: `Undefined custom abilities | ${context}`,
});
