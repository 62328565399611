import { realmApp } from "./realm";
import { logger } from "../logger";
import { mdbErrors } from "../mongodb";

// TODO: We should put all this in a realmUser object
// and include the schema

export function dbGetUserByLegacyId(legacyId) {
  logger.info("In dbGetUserByLegacyId");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.getUserByLegacyId(legacyId);
}

export function dbIncrementUserConsumptionBytesUsageBy(userId, by) {
  logger.info("In dbIncrementUserConsumptionBytesUsageBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserConsumptionBytesUsageBy(userId, by);
}

export function dbIncrementUserProcessingBytesUsageBy(userId, by) {
  logger.info("In dbIncrementUserProcessingBytesUsageBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserProcessingBytesUsageBy(userId, by);
}

export function dbIncrementUserAcquiredStorageBytesBy(userId, by) {
  logger.info("In dbIncrementUserAcquiredStorageBytesBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserAcquiredStorageBytesBy(userId, by);
}

export function dbIncrementUserAcquiredProcessingBytesBy(userId, by) {
  logger.info("In dbIncrementUserAcquiredProcessingBytesBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserAcquiredProcessingBytesBy(userId, by);
}
